@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap');

@font-face {
    font-family: "Nowbold";
    src: url("../public/nowbold.otf");
}
